import React from "react"
import Layout from "../../templates/layout"
import SEO from "../../components/seo/seo"
import Hero from "../../components/hero/hero"
import Card from "../../components/card/card"
import IconCard from "../../components/iconCard/iconCard"
import HaveQuestions from "../../components/haveQuestions/haveQuestions"
import BrandLogo from "../../images/logos/jevtana-logo.svg"
import QuestionsIcon from "../../images/icons/icon-questions-jevtana.svg"
import AffordIcon from "../../images/icons/icon-financial-jevtana.svg"
import AccessIcon from "../../images/icons/icon-access-jevtana.svg"
import RightArrow from "../../images/icons/right-arrow-icon.svg"
import { Link } from "gatsby"
import AssistCallout from "../../components/assistCallout/AssistCallout";
import Support from "../../images/icons/jevtana/jevtana-support-icon.svg";

import BackToTop from "../../components/backToTop/BackToTop"
import PrescribingInfo from "../../components/prescribingInfo/prescribingInfo"

const pageContent = () => (

    <Layout pageid="page-home" pageClass="hcp-jevtana-home">
      <SEO
      title="CareASSIST by Sanofi for eligible patients prescribed JEVTANA® (cabazitaxel) | HCP Site"
      keywords="CareASSIST, Sanofi, JEVTANA® (cabazitaxel), HCP Site"
      description="Info for providers on CareASSIST by Sanofi, including financial assistance, enrollment, access & reimbursement, and resource support for eligible JEVTANA patients. Contact a Care Manager or a Field Reimbursement Manager. See full Prescribing Info, including Boxed WARNING"
      />
      
      <Hero
      brandLogo={BrandLogo}
      headline="Access and financial support for your eligible patients"
      copy="CareASSIST is committed to helping remove barriers for eligible patients and caregivers throughout their treatment journey"
      heroImgClass="hero-img"
      hasButton={false}
      btnLink="https://sanoficareassisthcp.iassist.com/"
      btnText="Enroll your patient today"
      subheadline="Enroll your patient today"
      btnClass="jevtana green"
      hasLogo={true}
      piLink="/"
      brandAlt="Jevtana logo"
      linkId="hcp-jevtana-enroll-patient-today"
      />
      
      <section className="content-section" id="overview">
          <AssistCallout imgAlt="Care Manager icon" productClass="jevtana" img={Support} content="Care Managers are available to answer questions, provide more information, and support you and your patient every step of the way" />
          <h2>Our offerings:</h2>
      <div className="flex-section center gap-x-20">
          {/* Horizontal Icon Card Example, needs the extra 'horizontal' cardClass */}
          <IconCard
          icon={AffordIcon}
          alt="Financial assistance icon"
          cardClass="offer jevtana"
          cardTitle="Financial assistance"
          cardText="Discover how much your patients can save"
          hasButton={true}
          btnLink="/hcp/jevtana/financial-assistance"
          btnClass="green right-arrow"
          btnTitle="Discover how you can save"
          buttonIcon={RightArrow}
          linkId="hcp-jevtana-financial-assistance-link"
          />
          <IconCard
          icon={AccessIcon}
          alt="Access-reimbursement icon"
          cardClass="offer jevtana"
          cardTitle="Access &amp; reimbursement"
          cardText={<>CareASSIST can help with:<span className="fix-h">&nbsp;</span>
          &bull; Insurance verification<br/>
          &bull; Prior authorization investigations<br/>
          &bull; Coding and billing support<br/>
          &bull; Claims and appeals</>}
          // cardList="Benefits investigations|Prior authorization investigations|Coding and billing support|Claims and appeals"
          hasButton={true}
          btnLink="/hcp/jevtana/reimbursement"
          btnClass="green right-arrow"
          btnTitle="Discover how you can save"
          buttonIcon={RightArrow}
          linkId="hcp-jevtana-reimbursement-link"
          />
        </div>        
        <div className="title-bar">
          <h2>Useful resources</h2>
          {/* <Link to="/hcp/jevtana/resource-support/" className="view-all" title="View alll resources link">View all resources</Link> */}
        </div>
        <div className="flex-section between">

          <Card 
          cardTitle="Enrollment form"
          cardClass="useful-resources"
          cardText=""
          isExternalLink={true}
          linkUrl="../../pdfs/careassist-program-enrollment-form.pdf"
          linkClass="download"
          linkTitle="Download the Enrollment Form"
          linkId="hcp-jevtana-enroll-form" 
          />
          <Card 
          cardTitle="Copay Program flashcard"
          cardClass="useful-resources"
          cardText=""
          isExternalLink={true}
          linkUrl="../../pdfs/careassist-copay-program-hcp-flashcard.pdf"
          linkClass="download"
          linkTitle="Download the Copay Program flashcard"
          linkId="hcp-jevtana-copay-program-flashcard"
          />
          <Card 
          cardTitle="CareASSIST HCP overview brochure"
          cardClass="useful-resources"
          cardText=""
          isExternalLink={true}
          linkUrl="../../../pdfs/careassist-patient-support-program-overview-resource-hcp.pdf"
          linkClass="download"
          linkTitle="Download the enrollment form"
          linkId="hcp-jevtana-overview-brochure" 
          />
        </div>
        <PrescribingInfo linkId="hcp-jevtana-prescribing-information" link="https://products.sanofi.us/jevtana/jevtana.html" />
        <HaveQuestions 
        icon={QuestionsIcon}
        />
      </section>
      <div className="back-to-top-container">
        <BackToTop />
      </div>
    </Layout>

)

export default pageContent